import { BackpackIcon, DashboardIcon } from "@radix-ui/react-icons";
import { useLocation } from "@remix-run/react";
import { useCurrentRefinements, useInstantSearch } from "react-instantsearch";
import { Button, Flex } from "sparta";
import { RefinementMultiSelect } from "~/routes/search_v2/components/RefinementMultiSelect";
import { useAddSearchModal } from "~/routes/search_v2/components/SaveSearchEditingModal";
import { UpsertSavedSearchModal } from "~/routes/search_v2/components/UpsertSavedSearchModal";

export default function RefinementLists() {
  const modal = useAddSearchModal();
  const { items } = useCurrentRefinements();
  const { uiState } = useInstantSearch();
  const location = useLocation();

  const platformFromUrl = location.pathname.split("/")[1];
  // Utility function to format the platform string
  const formatPlatform = (platform) => {
    if (!platform) return "";

    // Specific mappings
    const mappings = {
      fedciv: "FedCiv",
      health: "Health",
      defense: "Defense",
      // Add other specific mappings here if needed
    };

    // Check for specific cases, else capitalize first letter
    return mappings[platform.toLowerCase()] || platform.charAt(0).toUpperCase() + platform.slice(1).toLowerCase();
  };
  const formattedPlatform = formatPlatform(platformFromUrl);

  const findRefinements = (attribute: string): string[] => {
    return items.find((item) => item.attribute === attribute)?.refinements.map((r) => r.value.toString()) || [];
  };

  return (
    <>
      <Flex direction={{ initial: "column", md: "row" }}>
        <Flex>
          <RefinementMultiSelect
            attribute="agency"
            sortBy={["name:asc"]}
            searchablePlaceholder="Agencies"
            limit={200}
            leftIcon={<BackpackIcon />}
          />
          <RefinementMultiSelect
            attribute="postType"
            searchable
            searchablePlaceholder="Categories"
            leftIcon={<DashboardIcon />}
          />
        </Flex>
        <Flex justify="between">
          <Button disabled={items.length === 0} onClick={() => modal.setOpen(true)}>
            Save Search
          </Button>
        </Flex>
      </Flex>
      <UpsertSavedSearchModal
        open={modal.open}
        onOpenChange={modal.setOpen}
        defaultValues={{
          query: uiState.posts.query as string,
          tags: uiState.posts.refinementList?.tags as string[],
          platforms: formattedPlatform ? [formattedPlatform] : [],
          postType: findRefinements("postType"),
          agencies: findRefinements("agency"),
        }}
      />
    </>
  );
}
